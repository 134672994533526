.App {
  text-align: center;
  height: 100%;
  /*font-family: Lato, Roboto, Arial, sans-serif;*/
  /*color: rgb(65,65,65);*/
}

#root {
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

fieldset {
  border: 0;
  padding: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.rdg-cell {
  border-inline-end: none;
  padding-inline-end: 18px;
  padding-inline-start: 18px;
}

.rdg {
  border: none;
}

.Resizer {
  background: #000;
  opacity: 0.1;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.rdg {
  --rdg-border-color: #eaeaea;
}

.rdg-font-small {
  --rdg-font-size: 12px;
  block-size: 250px;
}

.rdg-font-small .rdg-cell {
  border-inline-end: 1px solid var(--rdg-border-color);
  padding-inline-end: 6px;
  padding-inline-start: 16px;
  color: rgb(75, 85, 99);
}

.monaco-editor.rename-box,
.monaco-hover {
  top: 0;
}

.App a.sbui-typography-link {
  @apply text-sm text-brand-700 dark:text-brand-700 cursor-pointer;
}

.App .sbui-btn-primary {
  @apply text-white bg-brand-500 hover:bg-brand-600;
}
.App .sbui-formlayout--small .sbui-formlayout__label {
  @apply text-xs;
}

.App .sbui-toggle--active {
  @apply bg-brand-600 hover:bg-brand-400;
}

.App .sbui-btn, .App .sbui-input, .App .sbui-listbox, .App .sbui-select {
  @apply rounded-2xl py-3 shadow-none;
}

.App .sbui-input:focus, .App .sbui-listbox:focus, .App .sbui-select:focus {
  @apply border-brand-500;
}

.App .sbui-listbox {
  @apply focus:ring-brand-500 focus:ring-1 focus:border-brand-500 focus:outline-none;
}

.App .sbui-checkbox[type=checkbox]:checked {
  @apply bg-brand-500;
}

.App .sbui-checkbox[type=checkbox]:checked {
  @apply bg-brand-500;
}

.App .sbui-checkbox:hover  {
  @apply border-brand-500 bg-opacity-50;
}

.App .sbui-listbox-option--active {
  @apply text-brand-600 bg-brand-300;
}

.App .sbui-listbox-option__check--active {
  @apply text-brand-600;
}

.App .logo {
  font-family: "Space Mono";
}

.App .slack .sbui-input {
  @apply text-xs;
}

.App .sbui-formlayout {
  @apply gap-1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
